import countryTeaserTranslations from './CountryTeaser/translations';

const countryPanelTranslations = {
    'countryPanel.country': 'Polska',
    'countryPanel.tiles.footer': 'Liczba mieszkanców i gęstość zaludnienia w roku {{year}}. Liczba mieszkanców podana wg <a href=\'https://bdl.stat.gov.pl/\' target=\'_blank\'>Bazy Danych Lokalnych</a>.<br/>Powierzchnia podana wg <a href="https://www.openstreetmap.org/" target="_blank" rel="noreferrer">OpenStreetMap</a> jako suma powierzchni obszaru lądowego Polski i powierzchni morskich wód wewnętrznych.',
    'countryPanel.charts.popTrend.title': 'Zmiany liczby mieszkanców (lata {{minYear}} - {{maxYear}})',
    'countryPanel.charts.popTrend.footer': 'Liczba mieszkanców Polski w latach {{minYear}} - {{maxYear}} {{populationChangeType}} o {{populationChange}}%.',
    'countryPanel.charts.popTrend.populationChangeType.increase': 'wzrosła',
    'countryPanel.charts.popTrend.populationChangeType.decrease': 'spadła',
    'countryPanel.charts.topPopulationCities.title': '10 miast w Polsce z największą liczbą mieszkańców ({{topPopulationCitiesYear}} rok)',
    'countryPanel.charts.topPopulationCities.label': 'Liczba mieszkańców',
    'countryPanel.charts.topAreaCities.title': '10 miast w Polsce z największą powierzchnią (km2)',
    'countryPanel.charts.topAreaCities.label': 'Powierzchnia',
    'countryPanel.charts.topDensityCities.title': '10 miast w Polsce z największą gęstoscią zaludnienia (powyżej 50 tys. mieszkańców, {{topDensityCitiesYear}} rok) (osoby/km2)',
    'countryPanel.charts.topDensityCities.label': 'Gęstość zaludnienia',
    'countryPanel.charts.fastestGrowingCities.title': '10 najszybciej rosnących miast w Polsce (powyżej 50 tys. mieszkańców, lata {{fastestGrowingCitiesMinYear}} - {{fastestGrowingCitiesMaxYear}})',
    'countryPanel.charts.fastestGrowingCities.label': 'Wzrost liczby mieszkańców',
    'countryPanel.charts.fastestDecreasingCities.title': '10 najszybciej wyludniających się miast w Polsce (powyżej 50 tys. mieszkańców, lata {{fastestDecreasingCitiesMinYear}} - {{fastestDecreasingCitiesMaxYear}})',
    'countryPanel.charts.fastestDecreasingCities.label': 'Spadek liczby mieszkańców',
    ...countryTeaserTranslations,
};

export default countryPanelTranslations;

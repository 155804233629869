import teaserTranslations from './RegionTeaser/translations.en';

const translations = {
    "regionStatsTiles": "Population, area and population density for {{regionType}} {{region}} in {{year}}.<br/>Population according to {{sourceType}}.",
    "regionStatsTiles.regionType.region": "region",
    "regionStatsTiles.regionType.city": "locality",
    "regionStatsTiles.sourceType.bdl": "<a href='https://bdl.stat.gov.pl/' target='_blank'>Bazy Danych Lokalnych</a>",
    "regionStatsTiles.sourceType.worldpop": "<a href='https://www.worldpop.org/' target='_blank'>WorldPop</a> corrected by data imported from <a href='https://bdl.stat.gov.pl/' target='_blank'>Bazy Danych Lokalnych</a>",
    "regionPopulationTrend": "Population changes for {{regionType}} {{region}} in years {{minYear}} - {{maxYear}}. Population {{populationChangeType}} in these years by {{populationChange}}%.",
    "regionPopulationTrend.regionType.region": "region",
    "regionPopulationTrend.regionType.city": "locality",
    "regionPopulationTrend.changeType.increased": "increased",
    "regionPopulationTrend.changeType.decreased": "decreased",
    "regionPopulationRanking": "{{regionType}} ranking in region {{parentRegion}} by population. Ranking has been created for year {{year}}. {{regionType2}} {{region}} is at place {{rank}} in the ranking.",
    "regionPopulationRanking.regionType.county": "Counties",
    "regionPopulationRanking.regionType.community": "Communities",
    "regionPopulationRanking.regionType.city": "Localities",    
    "regionPopulationRanking.regionType2.region": "Region",
    "regionPopulationRanking.regionType2.city": "Locality",
    "regionAreaRanking": "{{regionType}} ranking in region {{parentRegion}} by area. {{regionType2}} {{region}} is at place {{rank}} in the ranking.",
    "regionAreaRanking.regionType.county": "Conities",
    "regionAreaRanking.regionType.community": "Communities",
    "regionAreaRanking.regionType.city": "Localities",    
    "regionAreaRanking.regionType2.region": "Region",
    "regionAreaRanking.regionType2.city": "Locality",
    "regionDensityRanking": "{{regionType}} ranking in region {{parentRegion}} by population density. Ranking has been created for year {{year}}. {{regionType2}} {{region}} is at place {{rank}} in the ranking.",
    "regionDensityRanking.regionType.county": "Counties",
    "regionDensityRanking.regionType.community": "Communities",
    "regionDensityRanking.regionType.city": "Localities",
    "regionDensityRanking.regionType2.region": "Region",
    "regionDensityRanking.regionType2.city": "Locality",
    ...teaserTranslations,
}

export default translations;

/* eslint-disable import/no-webpack-loader-syntax */
const voivodeshipTeaser = require('!!raw-loader!./regionTeaser.voivodeship.en.html');
const countyTeaser = require('!!raw-loader!./regionTeaser.county.en.html');
const communityTeaser = require('!!raw-loader!./regionTeaser.community.en.html');
const cityTeaser = require('!!raw-loader!./regionTeaser.city.en.html');

const regionTeaserTranslations = {
    "regionTeaser.voivodeship": voivodeshipTeaser.default,
    "regionTeaser.county": countyTeaser.default,
    "regionTeaser.community": communityTeaser.default,
    "regionTeaser.city": cityTeaser.default,
    'regionTeaser.location.west': 'western',
    'regionTeaser.location.north-west': 'north-western',
    'regionTeaser.location.north': 'northern',
    'regionTeaser.location.north-east': 'north-eastern',
    'regionTeaser.location.east': 'eastern',
    'regionTeaser.location.south-east': 'south-eastern',
    'regionTeaser.location.south': 'southern',
    'regionTeaser.location.south-west': 'south-western',
    'regionTeaser.location.central': 'central',
    'regionTeaser.county.adminCenters': 'The seat of the county is <b>{{regionAdminCenters}}</b>.',
    'regionTeaser.community.adminCenters': 'The seat of the community is <b>{{regionAdminCenters}}</b>.',
    'regionTeaser.voivodeship.smallCities': 'Small and medium-sized cities in the voivodeship (15k - 50k population): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.voivodeship.bigCities': 'Big cities in the voivodeship (more than 50k population): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.voivodeship.allCities': 'Bigger cities in the voivodeship (more than 15k population): <b>{{regionAllCities}}</b>.<br/>',
    'regionTeaser.county.smallCities': 'Small and medium-sized cities in the neighbourhood (15k - 50k population, up to 25 km away from the county center): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.county.bigCities': 'Big cities in the neighbourhood (more than 50k population, up to 50 km away the county center): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.county.allCities': 'Bigger cities in the neighbourhood (more than 15k population, up to 50 km away from the county center): <b>{{regionAllCities}}</b>.<br/>',
    'regionTeaser.community.smallCities': 'Small and medium-sized cities in the neighbourhood (15k - 50k population, up to 25 km away from the community center): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.community.bigCities': 'Big cities in the neighbourhood (more than 50k population, up to 50 km away the community center): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.community.allCities': 'Bigger cities in the neighbourhood (more than 15k population, up to 50 km away from the community center): <b>{{regionAllCities}}</b>.<br/>',
    'regionTeaser.city.smallCities': 'Other small and medium-sized cities in the neighbourhood (15k - 50k population, up to 25 km away): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.city.bigCities': 'Other big cities in the neighbourhood (more than 50k population, up to 50 km away): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.city.allCities': 'Other bigger cities in the neighbourhood (more than 15k population, up to 50 km away): <b>{{regionAllCities}}</b>.<br/>',
};

export default regionTeaserTranslations;
import countryTeaserTranslations from './CountryTeaser/translations.en';

const countryPanelTranslations = {
    'countryPanel.country': 'Poland',
    'countryPanel.tiles.footer': 'Population and population density in {{year}}. Population according to <a href=\'https://bdl.stat.gov.pl/\' target=\'_blank\'>Bazy Danych Lokalnych</a>.<br/>Area according to <a href="https://www.openstreetmap.org/" target="_blank" rel="noreferrer">OpenStreetMap</a> as the sum of the land area of Poland and the surface area of internal sea waters.',
    'countryPanel.charts.popTrend.title': 'Population changes (years {{minYear}} - {{maxYear}})',
    'countryPanel.charts.popTrend.footer': 'Poland population in {{minYear}} - {{maxYear}} {{populationChangeType}} by {{populationChange}}%.',
    'countryPanel.charts.popTrend.populationChangeType.increase': 'increased',
    'countryPanel.charts.popTrend.populationChangeType.decrease': 'decreased',
    'countryPanel.charts.topPopulationCities.title': '10 cities in Poland with the highest population (year {{topPopulationCitiesYear}})',
    'countryPanel.charts.topPopulationCities.label': 'Population',
    'countryPanel.charts.topAreaCities.title': '10 cities in Poland with the higest area (km2)',
    'countryPanel.charts.topAreaCities.label': 'Area',
    'countryPanel.charts.topDensityCities.title': '10 cities in Poland with the highest population density (with population above 50k, year {{topDensityCitiesYear}}) (people/km2)',
    'countryPanel.charts.topDensityCities.label': 'Population density',
    'countryPanel.charts.fastestGrowingCities.title': '10 fastest growing cities in Poland (with population above 50k, years {{fastestGrowingCitiesMinYear}} - {{fastestGrowingCitiesMaxYear}})',
    'countryPanel.charts.fastestGrowingCities.label': 'Population increase',
    'countryPanel.charts.fastestDecreasingCities.title': '10 fastest decreasing cities in Poland (with population above 50k, years {{fastestDecreasingCitiesMinYear}} - {{fastestDecreasingCitiesMaxYear}})',
    'countryPanel.charts.fastestDecreasingCities.label': 'Population decrease',
    ...countryTeaserTranslations,
};

export default countryPanelTranslations;

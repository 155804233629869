import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { getCurrentLang } from "utils/currentLangUtils";

import plTranslation from './translations';
import enTranslation from './translations.en';

const resources = {
    pl: {
        translation: { ...plTranslation },
    },
    en: {
        translation: { ...plTranslation, ...enTranslation },
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: getCurrentLang(),
        interpolation: {
            escapeValue: false,
            skipOnVariables: false,
        }
    });

export default i18n;
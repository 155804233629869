/* eslint-disable import/no-webpack-loader-syntax */
const infoAboutServicePage = require('!!raw-loader!./infoAboutServicePage.en.html');
const infoContactPage = require('!!raw-loader!./infoContactPage.en.html');
const infoForCompaniesPage = require('!!raw-loader!./infoForCompaniesPage.en.html');
const infoOurDataPage = require('!!raw-loader!./infoOurDataPage.en.html');
const infoPrivacyPolicyPage = require('!!raw-loader!./infoPrivacyPolicyPage.en.html');

const infoPagesTranslations = {
    'infoPage.AboutService': infoAboutServicePage.default,
    'infoPage.Contact': infoContactPage.default,
    'infoPage.ForCompanies': infoForCompaniesPage.default,
    'infoPage.OurData': infoOurDataPage.default,
    'infoPage.PrivacyPolicy': infoPrivacyPolicyPage.default,
};

export default infoPagesTranslations;

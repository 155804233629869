/* eslint-disable import/no-webpack-loader-syntax */
const countryIntro = require('!!raw-loader!./country.intro.en.html');
const countryUnits = require('!!raw-loader!./country.units.en.html');

const countryTeaserTranslations = {
    'countryTeaser.country': 'Poland',
    'countryTeaser.intro': countryIntro.default,
    'countryTeaser.units': countryUnits.default,
    'countryTeaser.populationChangeType.increase': 'increased',
    'countryTeaser.populationChangeType.decrease': 'decreased',
};

export default countryTeaserTranslations;

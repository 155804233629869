/* eslint-disable import/no-webpack-loader-syntax */
const voivodeshipTeaser = require('!!raw-loader!./regionTeaser.voivodeship.html');
const countyTeaser = require('!!raw-loader!./regionTeaser.county.html');
const communityTeaser = require('!!raw-loader!./regionTeaser.community.html');
const cityTeaser = require('!!raw-loader!./regionTeaser.city.html');

const regionTeaserTranslations = {
    "regionTeaser.voivodeship": voivodeshipTeaser.default,
    "regionTeaser.county": countyTeaser.default,
    "regionTeaser.community": communityTeaser.default,
    "regionTeaser.city": cityTeaser.default,
    'regionTeaser.location.west': 'zachodniej',
    'regionTeaser.location.north-west': 'północno-zachodniej',
    'regionTeaser.location.north': 'północnej',
    'regionTeaser.location.north-east': 'północno-wschodniej',
    'regionTeaser.location.east': 'wschodniej',
    'regionTeaser.location.south-east': 'południowo-wschodniej',
    'regionTeaser.location.south': 'południowej',
    'regionTeaser.location.south-west': 'południowo-zachodniej',
    'regionTeaser.location.central': 'centralnej',
    'regionTeaser.county.adminCenters': 'Siedzibą powiatu jest <b>{{regionAdminCenters}}</b>.',
    'regionTeaser.community.adminCenters': 'Siedzibą gminy jest <b>{{regionAdminCenters}}</b>.',
    'regionTeaser.voivodeship.smallCities': 'Małe i średnie miasta w województwie (15 - 50 tys. mieszkanców): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.voivodeship.bigCities': 'Duże miasta w województwie (powyżej 50 tys. mieszkanców): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.voivodeship.allCities': 'Większe miasta w województwie (powyżej 15 tys. mieszkanców): <b>{{regionAllCities}}</b>.<br/>',
    'regionTeaser.county.smallCities': 'Małe i średnie miasta w okolicy (15 - 50 tys. mieszkanców, w odległości do 25 km od centrum powiatu): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.county.bigCities': 'Duże miasta w okolicy (powyżej 50 tys. mieszkanców, w odległości do 50 km od centrum powiatu): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.county.allCities': 'Większe miasta w okolicy (powyżej 15 tys. mieszkanców, w odległości do 50 km od centrum powiatu): <b>{{regionAllCities}}</b>.<br/>',
    'regionTeaser.community.smallCities': 'Małe i średnie miasta w okolicy (15 - 50 tys. mieszkanców, w odległości do 25 km od centrum gminy): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.community.bigCities': 'Duże miasta w okolicy (powyżej 50 tys. mieszkanców, w odległości do 50 km od centrum gminy): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.community.allCities': 'Większe miasta w okolicy (powyżej 15 tys. mieszkanców, w odległości do 50 km od centrum gminy): <b>{{regionAllCities}}</b>.<br/>',
    'regionTeaser.city.smallCities': 'Inne małe i średnie miasta w okolicy (15 - 50 tys. mieszkanców, w odległości do 25 km): <b>{{regionSmallCities}}</b>.<br/>',
    'regionTeaser.city.bigCities': 'Inne duże miasta w okolicy (powyżej 50 tys. mieszkanców, w odległości do 50 km): <b>{{regionBigCities}}</b>.<br/>',
    'regionTeaser.city.allCities': 'Inne większe miasta w okolicy (powyżej 15 tys. mieszkanców, w odległości do 50 km): <b>{{regionAllCities}}</b>.<br/>',
};

export default regionTeaserTranslations;
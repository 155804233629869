import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrentLang, changeCurrentLang, SUPPORTED_LANGS, LANG_SPECS } from "utils/currentLangUtils";

const LangSelection = () => {

    const { t } = useTranslation();
    const currentLang = getCurrentLang();

    const selectLang = (lang) => {
        changeCurrentLang(lang);
    };

    return (
        <>
            {SUPPORTED_LANGS.filter((lang) => currentLang !== lang).map((lang, idx) =>
                <Tooltip key={idx} title={`${t('topBar.changeLanguage')} ${LANG_SPECS[lang].name}`}>
                    <Box
                        onClick={() => selectLang(lang)}
                        sx={{
                            cursor: 'pointer',
                            border: LANG_SPECS[lang].border || 'none',  
                        }}>
                        <img
                            src={LANG_SPECS[lang].icon}
                            alt={LANG_SPECS[lang].name}
                            width={22}
                            height={16}
                        />
                    </Box>
                </Tooltip>
            )}
        </>
    );
};

export default LangSelection;
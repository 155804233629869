const SUPPORTED_LANGS = ['pl', 'en'];
const DEFAULT_LANG = 'pl';
const LANG_SPECS = {
    pl: { icon: '/assets/icons/PL.png', name: 'Polski', border: 'solid red 1px' },
    en: { icon: '/assets/icons/GB.png', name: 'English' },
}

const getCurrentLang = () => {
    
    const host = window.location.hostname;
    const langPrefix = host.substring(0, host.indexOf('.'));
    if (SUPPORTED_LANGS.includes(langPrefix)) {
        return langPrefix;
    }
    
    return DEFAULT_LANG;
};

const changeCurrentLang = (newLang) => {

    const currentLang = getCurrentLang();
    const currentHost = window.location.hostname;
    const newLangPrefix = newLang === DEFAULT_LANG ? '' : `${newLang}.`; 
    
    let newHost;
    if (currentLang === DEFAULT_LANG) {
        newHost = `${newLangPrefix}${currentHost}`
    } else {
        newHost = currentHost.replace(`${currentLang}.`, newLangPrefix)
    }

    window.location.href = window.location.href.replace(currentHost, newHost);

};

export { getCurrentLang, changeCurrentLang, SUPPORTED_LANGS, LANG_SPECS, DEFAULT_LANG };


import regionPageTranslations from 'pages/RegionPage/translations.en';
import infoPagesTranslations from 'pages/translations.en';

const translations = {
    "topBar.serviceTitle": "Twoja Okolica <span style='color: #B80000'>.Online</span>",
    "topBar.changeLanguage": "Change language: ",
    "topBar.infoOurData": "About Data",
    "topBar.infoAboutService": "About Service",
    "topBar.infoForCompanies": "For Companies",
    "topBar.infoContact": "Contact",
    "topBar.infoPrivacyPolicy": "Privacy Policy",
    "searchBar.searchCityOrRegion": "Search city or region ...",
    "searchBar.noResults": "No results found for",
    "map.labelMaximize": "Maximize",
    "map.labelMinimize": "Minimize",
    "header.previousRegion": "Previous Region:",
    "header.selectVoivodeshipId": "Select voivodeship",
    "header.selectCounty": "Select county in region",
    "header.selectCommunity": "Select community in region",
    "header.selectCity": "Select city in region",
    "regionHeader.tooltipShareOnWhatsapp": "Share on WhatsApp",
    "regionHeader.tooltipShareOnTwitter": "Share on Twitter",
    "regionHeader.tooltipShareOnFacebook": "Share on Facebook",
    "regionHeader.tooltipShareOnLinkedIn": "Share on LinkedIn",
    "regionHeader.tooltipShareOnEmail": "Send this page by email",
    "regionHeader.tooltipCopyLink": "Copy link",
    "regionHeader.tooltipCopyLinkDone": "Link has been copied!",
    "regionHeader.tooltipPrintPage": "Print the page",
    "regionTab.tabTitleBasicInformation": "Overview",
    "regionTab.tabTitleRegionDirections": "Directions",
    "regionTab.tabTitleRegionLeisure": "Leisure",
    "regionTab.tabTitleRegionServices": "Services",
    "regionTab.tabTitleRegionMap": "Map",
    "regionTab.pageTitleBasicInformation": "Population, area, biggest cities, statistics",
    "regionTab.pageTitleRegionDirections": "Directions to the region, directions in the region",
    "regionTab.pageTitleRegionLeisure": "Leisure",
    "regionTab.pageTitleRegionServices": "Services",
    "regionTab.pageTitleRegionMap": "Region map",
    "regionStats.tileTitlePopulation": "Population",
    "regionStats.tileTitleArea": "Area",
    "regionStats.tileTitleDensity": "Population density",
    "regionStats.tileFooterRankVoivodeship": "Place {{rank}} in the country",
    "regionStats.tileFooterRankCounty": "Place {{rank}} in the voivodeship",
    "regionStats.tileFooterRankCommunity": "Place {{rank}} in the county",
    "regionStats.tileFooterRankCity": "Place {{rank}} in the community",
    "regionStats.tileAreaUnits": "km2",
    "regionStats.tileDensityUnits": "people/km2",
    "regionStats.chartPopTrendTitle": "Changes in population",
    "regionStats.chartPopTrendTooltipYear": "Year",
    "regionStats.chartPopTrendTooltipPopulation": "Population",
    "regionStats.chartRankingPopTitle": "Population ranking",
    "regionStats.chartRankingPopValueLabel": "Population",
    "regionStats.chartRankingAreaTitle": "Area ranking (km2)",
    "regionStats.chartRankingAreaValueLabel": "Area",
    "regionStats.chartRankingDensityTitle": "Population density ranking (osoby/km2)",
    "regionStats.chartRankingDensityValueLabel": "Population density",
    "region.subRegionsTitle": "Voivodeships in Poland",
    "region.subRegionsTitleVoivodeship": "Counties in the voivodeship",
    "region.subRegionsTitleCounty": "Communities in the county",
    "region.subRegionsTitleCommunity": "Cities in the community",
    "regionDirections.titleCountryDirections": "Directions to region",
    "regionDirections.teaserCountryDirectionsRegion": "Directions from the biggest cities in Poland to region {{name}}",
    "regionDirections.teaserCountryDirectionsCity": "Directions from the biggest cities in Poland to city {{name}}",
    "regionDirections.titleRegionDirections": "Directions in region",
    "regionDirections.teaserRegionDirectionsRegion": "Directions to the biggest cities in region {{name}}",
    "regionDirections.teaserRegionDirectionsCity": "Directions to the biggest cities in the neighbourhood from city {{name}}",
    "regionDirections.labelRoute": "Route",
    "regionDirections.labelDrivingTime": "Driving time",
    "regionDirections.labelDrivingDistance": "Distance",
    "regionDirections.labelStartLocation": "Start: {{name}} center",
    "regionDirections.labelDestinationLocation": "Destination: {{name}} center",
    "regionDirections.labelNoDirections": "No directions information for selected region/city",
    "regionAmenities.labelNoAmenities": "No search results for selected region and provided parameters",
    "regionAmenities.labelAmenitiesSearch": "Search by name or category",
    "regionAmenities.amenity.shops": "Shops",
    "regionAmenities.amenity.healthcare": "Healthcare",
    "regionAmenities.amenity.financial": "Finance",
    "regionAmenities.amenity.transportation": "Transportation",
    "regionAmenities.amenity.temples": "Temples",
    "regionAmenities.amenity.basics": "Basics",
    "regionAmenities.amenity.gastronomy": "Gastronomy",
    "regionAmenities.amenity.tourism": "Tourism",
    "regionAmenities.amenity.hotels": "Hotels",
    "regionAmenities.amenity.recreation": "Recreation",
    "regionAmenities.amenity.entertainment": "Entertainment",
    "regionAmenities.amenity.shops.long": "Shops",
    "regionAmenities.amenity.healthcare.long": "Healthcare",
    "regionAmenities.amenity.financial.long": "Finance",
    "regionAmenities.amenity.transportation.long": "Transportation",
    "regionAmenities.amenity.temples.long": "Temples",
    "regionAmenities.amenity.basics.long": "Basic Services",
    "regionAmenities.amenity.gastronomy.long": "Gastronomy",
    "regionAmenities.amenity.tourism.long": "Tourism",
    "regionAmenities.amenity.hotels.long": "Hotels",
    "regionAmenities.amenity.recreation.long": "Sports and Recreation",
    "regionAmenities.amenity.entertainment.long": "Entertainment",
    "regionAmenities.titleStats": "Services and amenities in {{regionType}} {{regionName}} matching provided criteria ({{amenitiesTotal}}):",
    "regionAmenities.titleStats.regionType.region": "region",
    "regionAmenities.titleStats.regionType.city": "city",
    "regionAmenities.labelSelectAll": "Select All",
    "regionAmenities.labelShowAll": "Show All",
    "regionAmenities.labelAmenityWebsite": "Web Page",
    "regionAmenities.labelAmenityWikipedia": "Wikipedia",
    "regionAmenities.labelAmenityShowSimilarCategories": "Show all amenities in this category in {{regionType}}",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.voivodeship": "voivodeship",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.county": "county",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.community": "community",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.city": "city",
    "regionAmenities.labelAmenityAddToMap": "Add to map",
    "regionAmenities.labelAmenityRemoveFromMap": "Remove from map",
    "regionMap.titleCurrentOwnerData": "Current user data",
    "regionMap.titleManageMap": "Adjust map to your needs",
    "regionMap.buttonOwnerLogIn": "Log In",
    "regionMap.buttonOwnerCreate": "Create New User",
    "regionMap.buttonOwnerLoginFetch": "Read",
    "regionMap.buttonOwnerLoginCancel": "Cancel",
    "regionMap.labelOwnerCreateConditions": "Creating own maps is provided in test quality so use at own risk. Please contact us kontakt@twojaokolica.online if you are using this function so that we can inform you about future changes. You are accepting the conditions by clicking Confirm.",
    "regionMap.buttonOwnerCreateConfirm": "Confirm",
    "regionMap.buttonOwnerCreateCancel": "Cancel",
    "regionMap.buttonOwnerLogOut": "Log Out",
    "regionMap.labelOwnerIdentifier": "Identifier",
    "regionMap.inputLabelOwnerIdentifier": "User Identifier",
    "regionMap.labelOwnerIdentifierHint": "You will need the Identifier to access the maps again!",
    "regionMap.labelOwnerIdentifierCopied": "User identifier copied",
    "regionMap.chipLabelGeneralMap": "General",
    "regionMap.chipLabelCustomMap": "Map {{mapIdx}}",
    "regionMap.chipLabelCreateMap": "Add New Map",
    "regionMap.chipLabelOwnerPanel": "User Panel",
    "regionMap.titleCustomMapAccessData": "Access Data",
    "regionMap.labelCustomMapURLAddress": "Map URL",
    "regionMap.labelCustomMapURLAddressCopied": "Map URL copied",
    "regionMap.hintPreviewCustomMap": "Show preview in separate tab",
    "regionMap.hintShareURLAddress": "Use to share the map",
    "regionMap.titleCustomMapSettings": "Map settings",
    "regionMap.titleCustomMapBoundaries": "Region boundaries",
    "regionMap.titleCustomMapServiceAmenities": "Service amenities",
    "regionMap.titleCustomMapLeisureAmenities": "Leisure amenities",
    "regionMap.buttonCustomMapAddAmenity": "Add",
    "regionMap.buttonDeleteCustomMap": "Delete map ...",
    "regionMap.buttonCancelDeleteCustomMap": "Cancel",
    "regionMap.buttonConfirmDeleteCustomMap": "Confirm map delete",
    "regionMap.labelUserLocationLongitude": "Longitude:",
    "regionMap.labelUserLocationLatitude": "Latitude:",
    "regionMap.titleUserLocations": "User locations",
    "regionMap.buttonAddUserLocation": "Add",
    "regionMap.inputLabelUserLocationName": "User location name",
    "regionMap.inputLabelUserLocationLongitude": "Longitude",
    "regionMap.inputLabelUserLocationLatitude": "Latitude",
    "regionMap.hintUserLocationCoordinatesHint": "You can enter coordinates directly or by clicking on the map above",
    "regionMap.buttonConfirmUserLocationEditing": "Confirm",
    "regionMap.buttonCancelUserLocationEditing": "Cancel"
}

export default { ...translations, ...regionPageTranslations, ...infoPagesTranslations };
import { lazy, Suspense } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import SimpleLayout from 'layouts/simple';
import NavigationLayout from 'layouts/navigation';

const RegionPage = lazy(() => import('pages/RegionPage'));
const InfoOurDataPage = lazy(() => import('pages/InfoOurDataPage'));
const InfoAboutServicePage = lazy(() => import('pages/InfoAboutServicePage'));
const InfoForCompaniesPage  = lazy(() => import('pages/InfoForCompaniesPage'));
const InfoContactPage = lazy(() => import('pages/InfoContactPage'));
const InfoPrivacyPolicyPage = lazy(() => import('pages/InfoPrivacyPolicyPage'));
const Page404 = lazy(() => import('pages/Page404'));

const SuspensedRegionPage = () => (
    <Suspense>
        <RegionPage />
    </Suspense>
);

export default function Router() {
    const routes = useRoutes([
        {
            path: '/',
            element: <NavigationLayout />,
            children: [
                { element: <SuspensedRegionPage />, index: true },
            ],
        },
        {
            path: '/region',
            element: <NavigationLayout />,
            children: [
                { element: <Navigate to="/" />, index: true },
                { path: ':voivodeshipRefName', element: <SuspensedRegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName', element: <SuspensedRegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName', element: <SuspensedRegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName/:cityRefName', element: <SuspensedRegionPage /> },
            ],
        },
        {
            path: '/tree', // kept here only for backwards compatibility
            element: <NavigationLayout />,
            children: [
                { element: <Navigate to="/" />, index: true },
                { path: ':voivodeshipRefName', element: <SuspensedRegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName', element: <SuspensedRegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName', element: <SuspensedRegionPage /> },
                { path: ':voivodeshipRefName/:countyRefName/:communityRefName/:cityRefName', element: <SuspensedRegionPage /> },
            ],
        },
        {
            path: '/our-data-info',
            element: <NavigationLayout />,
            children: [
                { element: <Suspense><InfoOurDataPage /></Suspense>, index: true },
            ],
        },
        {
            path: '/about-service-info',
            element: <NavigationLayout />,
            children: [
                { element: <Suspense><InfoAboutServicePage /></Suspense>, index: true },
            ],
        },
        {
            path: '/for-companies',
            element: <NavigationLayout />,
            children: [
                { element: <Suspense><InfoForCompaniesPage /></Suspense>, index: true },
            ],
        },
        {
            path: '/contact-info',
            element: <NavigationLayout />,
            children: [
                { element: <Suspense><InfoContactPage /></Suspense>, index: true },
            ],
        },
        {
            path: '/privacy-policy',
            element: <NavigationLayout />,
            children: [
                { element: <Suspense><InfoPrivacyPolicyPage /></Suspense>, index: true },
            ],
        },
        {
            path: '/404',
            element: <SimpleLayout />,
            children: [
                { element: <Suspense><Page404 /></Suspense>, index: true },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}

import axios from 'axios';

import { getCurrentLang } from 'utils/currentLangUtils';

class RegionAmenitiesService {

    constructor(regionAmenitiesApiUrl) {
        this.regionAmenitiesApiUrl = regionAmenitiesApiUrl;
    }

    async getAmenityCategories(amenityCategoryGroups, lang) {

        const getUrl = `${this.regionAmenitiesApiUrl}/public/amenity-category`;
        const params = { categoryGroups: amenityCategoryGroups.join(','), lang}
        const { data: content } = await axios.get(getUrl, { params });
        return content.data;
    }

    async getAmenities(regionId, categories, lang, offset, limit, query) {
        
        const getUrl = `${this.regionAmenitiesApiUrl}/public/amenity`;
        const params = {
            regionId, lang, offset, limit, query, categories: categories.join(','),
        };
        const { data: content } = await axios.get(getUrl, { params });
        return { amenities: content.data, total: content.total, counts: content.counts };
    }

    async getAmenityDetails(id) {

        const getUrl = `${this.regionAmenitiesApiUrl}/public/amenity/${id}`;
        const { data: content } = await axios.get(getUrl);
        return content.data;
    }

    async getAmenityTeaser(id) {

        const getUrl = `${this.regionAmenitiesApiUrl}/public/amenity/${id}/teaser`;
        const { data: content } = await axios.get(getUrl, { params: { lang: getCurrentLang() } });
        return content.data;

    }

    async getAmenityNeighbours(id) {

        const getUrl = `${this.regionAmenitiesApiUrl}/public/amenity/${id}/neighbours`;
        const { data: content } = await axios.get(getUrl);
        return content.data;

    }
};

export default RegionAmenitiesService;
import regionPageTranslations from 'pages/RegionPage/translations';
import infoPagesTranslations from 'pages/translations';

const translations = {
    "topBar.serviceTitle": "Twoja Okolica <span style='color: #B80000'>.Online</span>",
    "topBar.changeLanguage": "Zmień język: ",
    "topBar.infoOurData": "O Danych",
    "topBar.infoAboutService": "O Serwisie",
    "topBar.infoForCompanies": "Dla Firm",
    "topBar.infoContact": "Kontakt",
    "topBar.infoPrivacyPolicy": "Polityka Prywatności",
    "searchBar.searchCityOrRegion": "Szukaj miejscowości lub regionu ...",
    "searchBar.noResults": "Nie znaleziono żadnych wyników dla",
    "map.labelMaximize": "Powiększ",
    "map.labelMinimize": "Pomniejsz",
    "header.previousRegion": "Poprzedni Region:",
    "header.selectVoivodeshipId": "Wybierz województwo",
    "header.selectCounty": "Wybierz powiat w regionie",
    "header.selectCommunity": "Wybierz gminę w regionie",
    "header.selectCity": "Wybierz miejscowość w regionie",
    "regionHeader.tooltipShareOnWhatsapp": "Udostępnij tą stronę na WhatsAppie",
    "regionHeader.tooltipShareOnTwitter": "Udostępnij tą stronę na Twiterze",
    "regionHeader.tooltipShareOnFacebook": "Udostępnij tą stronę na Facebooku",
    "regionHeader.tooltipShareOnLinkedIn": "Udostępnij tą stronę na LinkedIn",
    "regionHeader.tooltipShareOnEmail": "Wyślij tą stronę mailem",
    "regionHeader.tooltipCopyLink": "Skopiuj link",
    "regionHeader.tooltipCopyLinkDone": "Link został skopiowany!",
    "regionHeader.tooltipPrintPage": "Wydrukuj stronę",
    "regionTab.tabTitleBasicInformation": "Przegląd",
    "regionTab.tabTitleRegionDirections": "Dojazdy",
    "regionTab.tabTitleRegionLeisure": "Wypoczynek",
    "regionTab.tabTitleRegionServices": "Usługi",
    "regionTab.tabTitleRegionMap": "Mapa",
    "regionTab.pageTitleBasicInformation": "Liczba ludności, powierzchnia, największe miasta, statystyki",
    "regionTab.pageTitleRegionDirections": "Dojazdy do regionu, dojazdy w regionie",
    "regionTab.pageTitleRegionLeisure": "Wypoczynek i gastronomia",
    "regionTab.pageTitleRegionServices": "Usługi i infrastruktura",
    "regionTab.pageTitleRegionMap": "Mapa regionu",
    "regionStats.tileTitlePopulation": "Liczba mieszkańców",
    "regionStats.tileTitleArea": "Powierzchnia",
    "regionStats.tileTitleDensity": "Gęstość zaludnienia",
    "regionStats.tileFooterRankVoivodeship": "{{rank}} województwo w kraju",
    "regionStats.tileFooterRankCounty": "{{rank}} powiat w województwie",
    "regionStats.tileFooterRankCommunity": "{{rank}} gmina w powiecie",
    "regionStats.tileFooterRankCity": "{{rank}} miejscowość w gminie",
    "regionStats.tileAreaUnits": "km2",
    "regionStats.tileDensityUnits": "osób/km2",
    "regionStats.chartPopTrendTitle": "Zmiany liczby mieszkańców",
    "regionStats.chartPopTrendTooltipYear": "Rok",
    "regionStats.chartPopTrendTooltipPopulation": "Liczba mieszkańców",
    "regionStats.chartRankingPopTitle": "Ranking liczby mieszkańców",
    "regionStats.chartRankingPopValueLabel": "Liczba mieszkańców",
    "regionStats.chartRankingAreaTitle": "Ranking powierzchni (km2)",
    "regionStats.chartRankingAreaValueLabel": "Powierzchnia",
    "regionStats.chartRankingDensityTitle": "Ranking gęstości zaludnienia (osoby/km2)",
    "regionStats.chartRankingDensityValueLabel": "Gęstość zaludnienia",
    "region.subRegionsTitle": "Województwa w Polsce",
    "region.subRegionsTitleVoivodeship": "Powiaty w województwie",
    "region.subRegionsTitleCounty": "Gminy w powiecie",
    "region.subRegionsTitleCommunity": "Miejscowości w gminie",
    "regionDirections.titleCountryDirections": "Dojazdy do regionu",
    "regionDirections.teaserCountryDirectionsRegion": "Dojazdy z największych miast w Polsce do regionu {{name}}",
    "regionDirections.teaserCountryDirectionsCity": "Dojazdy z największych miast w Polsce do miejscowości {{name}}",
    "regionDirections.titleRegionDirections": "Dojazdy w regionie",
    "regionDirections.teaserRegionDirectionsRegion": "Dojazdy do największych miast w regionie {{name}}",
    "regionDirections.teaserRegionDirectionsCity": "Dojazdy do największych miast w okolicy z miejscowości {{name}}",
    "regionDirections.labelRoute": "Trasa",
    "regionDirections.labelDrivingTime": "Czas dojazdu",
    "regionDirections.labelDrivingDistance": "Długość",
    "regionDirections.labelStartLocation": "Start: {{name}} Centrum",
    "regionDirections.labelDestinationLocation": "Cel: {{name}} Centrum",
    "regionDirections.labelNoDirections": "Brak informacji na temat dojazdów do wybranego regionu/miejscowości",
    "regionAmenities.labelNoAmenities": "Brak wyników dla wybranego regionu i parametrów wyszukiwania",
    "regionAmenities.labelAmenitiesSearch": "Szukaj według nazwy lub kategorii",
    "regionAmenities.amenity.shops": "Zakupy",
    "regionAmenities.amenity.healthcare": "Zdrowie",
    "regionAmenities.amenity.financial": "Finanse",
    "regionAmenities.amenity.transportation": "Transport",
    "regionAmenities.amenity.temples": "Świątynie",
    "regionAmenities.amenity.basics": "Podstawowe",
    "regionAmenities.amenity.gastronomy": "Gastronomia",
    "regionAmenities.amenity.tourism": "Turystyka",
    "regionAmenities.amenity.hotels": "Noclegi",
    "regionAmenities.amenity.recreation": "Rekreacja",
    "regionAmenities.amenity.entertainment": "Rozrywka",
    "regionAmenities.amenity.shops.long": "Zakupy",
    "regionAmenities.amenity.healthcare.long": "Zdrowie",
    "regionAmenities.amenity.financial.long": "Finanse",
    "regionAmenities.amenity.transportation.long": "Transport",
    "regionAmenities.amenity.temples.long": "Świątynie",
    "regionAmenities.amenity.basics.long": "Usługi Podstawowe",
    "regionAmenities.amenity.gastronomy.long": "Gastronomia",
    "regionAmenities.amenity.tourism.long": "Turystyka",
    "regionAmenities.amenity.hotels.long": "Noclegi",
    "regionAmenities.amenity.recreation.long": "Sport i Rekreacja",
    "regionAmenities.amenity.entertainment.long": "Rozrywka i Kultura",
    "regionAmenities.titleStats": "Usługi i udogodnienia w {{regionType}} {{regionName}} spełniające zadane kryteria ({{amenitiesTotal}}):",
    "regionAmenities.titleStats.regionType.region": "regionie",
    "regionAmenities.titleStats.regionType.city": "miejscowości",
    "regionAmenities.labelSelectAll": "Wybierz Wszystkie",
    "regionAmenities.labelShowAll": "Pokaż Wszystkie",
    "regionAmenities.labelAmenityWebsite": "Strona Internetowa",
    "regionAmenities.labelAmenityWikipedia": "Wikipedia",
    "regionAmenities.labelAmenityShowSimilarCategories": "Pokaż wszystkie lokalizacje tego typu w {{regionType}}",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.voivodeship": "województwie",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.county": "powiecie",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.community": "gminie",
    "regionAmenities.labelAmenityShowSimilarCategories.regionType.city": "miejscowości",
    "regionAmenities.labelAmenityAddToMap": "Dodaj do mapy",
    "regionAmenities.labelAmenityRemoveFromMap": "Usuń z mapy",
    "regionMap.titleCurrentOwnerData": "Dane bieżącego użytkownika",
    "regionMap.titleManageMap": "Dostosuj mapę do swoich potrzeb",
    "regionMap.buttonOwnerLogIn": "Zaloguj Się",
    "regionMap.buttonOwnerCreate": "Dodaj Nowego Użytkownika",
    "regionMap.buttonOwnerLoginFetch": "Wczytaj",
    "regionMap.buttonOwnerLoginCancel": "Przerwij",
    "regionMap.labelOwnerCreateConditions": "Tworzenie własnych map jest udostępniane w testowej formie i może nie działać niezawodnie. Korzystasz na własne ryzyko. Prosimy o kontakt na adres kontakt@twojaokolica.online, jeśli korzystasz z tej funkcji, abyśmy mogli poinformować Cię o ewentualnych zmianach. Klikając Potwierdź, akceptujesz te warunki.",
    "regionMap.buttonOwnerCreateConfirm": "Potwierdź",
    "regionMap.buttonOwnerCreateCancel": "Przerwij",
    "regionMap.buttonOwnerLogOut": "Wyloguj",
    "regionMap.labelOwnerIdentifier": "Identifykator",
    "regionMap.inputLabelOwnerIdentifier": "Identifykator Użytkownika",
    "regionMap.labelOwnerIdentifierHint": "Identifykator będzie konieczny do późniejszego dostępu do Twoich map!",
    "regionMap.labelOwnerIdentifierCopied": "Identyfikator użytkownika skopiowany",
    "regionMap.chipLabelGeneralMap": "Ogólna",
    "regionMap.chipLabelCustomMap": "Mapa {{mapIdx}}",
    "regionMap.chipLabelCreateMap": "Dodaj Nową Mapę",
    "regionMap.chipLabelOwnerPanel": "Panel Użytkownika",
    "regionMap.titleCustomMapAccessData": "Dane dostępowe",
    "regionMap.labelCustomMapURLAddress": "Adres URL Mapy",
    "regionMap.labelCustomMapURLAddressCopied": "Adres URL mapy skopiowany",
    "regionMap.hintPreviewCustomMap": "Pokaż podgląd mapy w osobnej zakładce",
    "regionMap.hintShareURLAddress": "Wykorzystaj do udostępnienia Twojej mapy",
    "regionMap.titleCustomMapSettings": "Ustawienia mapy",
    "regionMap.titleCustomMapBoundaries": "Granice regionu",
    "regionMap.titleCustomMapServiceAmenities": "Obiekty z kategorii Usługi",
    "regionMap.titleCustomMapLeisureAmenities": "Obiekty z kategorii Wypoczynek",
    "regionMap.buttonCustomMapAddAmenity": "Dodaj",
    "regionMap.buttonDeleteCustomMap": "Usuń mapę ...",
    "regionMap.buttonCancelDeleteCustomMap": "Przerwij",
    "regionMap.buttonConfirmDeleteCustomMap": "Potwierdź usunięcie mapy",
    "regionMap.labelUserLocationLongitude": "Długość geograficzna:",
    "regionMap.labelUserLocationLatitude": "Szerokość geograficzna:",
    "regionMap.titleUserLocations": "Lokalizacje użytkownika",
    "regionMap.buttonAddUserLocation": "Dodaj",
    "regionMap.inputLabelUserLocationName": "Nazwa lokalizacji",
    "regionMap.inputLabelUserLocationLongitude": "Długość geograficzna",
    "regionMap.inputLabelUserLocationLatitude": "Szerokość geograficzna",
    "regionMap.hintUserLocationCoordinatesHint": "Możesz wprowadzić współrzędne bezpośrednio lub kliknąć lokalizację na mapie powyżej",
    "regionMap.buttonConfirmUserLocationEditing": "Zatwierdź",
    "regionMap.buttonCancelUserLocationEditing": "Przerwij"
}

export default { ...translations, ...regionPageTranslations, ...infoPagesTranslations };